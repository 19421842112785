import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { Workflow } from "../models/workflow";
import { WorkflowListItem } from "../models/workflowlistitem";
import { PermissionsEnum, PlatformSettings } from "../constants/settings.enum";

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  private possibleWorkFlows: WorkflowListItem[] = [
    // GENERIC
    { "id": "bc2bfdf3-b079-49a2-8b93-717b8ebaa189", workflowType: WorkFlowTypeEnum.Generic, translationKey: "WorkFlowRequests.RequestTypeNames.SignYourself", bySettingName: PlatformSettings.Allow_WF_SignYourself },
    { "id": "de65f26a-a02e-4ca1-9356-d8cd2b83477a", workflowType: WorkFlowTypeEnum.Generic, translationKey: "WorkFlowRequests.RequestTypeNames.SignatureRequest", bySettingName: PlatformSettings.Allow_WF_SignatureRequest },
    { "id": "79831102-fd26-43e0-aebb-70f09e8f5e13", workflowType: WorkFlowTypeEnum.Generic, translationKey: "WorkFlowRequests.RequestTypeNames.SecureSharing", bySettingName: PlatformSettings.Allow_WF_SecureFileSharing },
    // TAX
    { "id": "eb34eb49-7828-4eef-afd0-41771544199b", workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.IncomeTax", bySettingName: PlatformSettings.Allow_WF_IncomeTax },
    { "id": "35927385-d6e4-4bff-877e-fd8f57dc1ba2", workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.CorporateTax", bySettingName: PlatformSettings.Allow_WF_CorporateTax },
    { "id": "eca3964f-a51c-4f91-8e01-018ae6d80f2a", workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.Vat", bySettingName: PlatformSettings.Allow_WF_VAT },
    { "id": "b57cc769-34be-4713-8378-bb1dca5f185e", workflowType: WorkFlowTypeEnum.Tax, translationKey: "WorkFlowRequests.RequestTypeNames.EuVat", bySettingName: PlatformSettings.Allow_WF_EUVAT },
    // Chamber of Commerce
    { "id": "d9acd49e-6c4c-44fd-b524-f9c2fb5f5dfc", workflowType: WorkFlowTypeEnum.ChamberOfCommerce, translationKey: "WorkFlowRequests.RequestTypeNames.FilingDefault", bySettingName: PlatformSettings.Allow_WF_Filing },
    { "id": "92e3a74c-9080-4734-a07d-7ff6ce320a41", workflowType: WorkFlowTypeEnum.ChamberOfCommerce, translationKey: "WorkFlowRequests.RequestTypeNames.FilingAudit", bySettingName: PlatformSettings.Allow_WF_FilingWithReport },
    // Sbr Nexus
    { "id": "3f4f37c1-9256-4bee-b04c-db14991bc7ff", workflowType: WorkFlowTypeEnum.SbrNexus, translationKey: "WorkFlowRequests.RequestTypeNames.SbrBankDefault", bySettingName: PlatformSettings.Allow_WF_SbrBank  },
    { "id": "ecf83848-0bf8-4d06-b1f6-a2b5e5077b32", workflowType: WorkFlowTypeEnum.SbrNexus, translationKey: "WorkFlowRequests.RequestTypeNames.SbrBankAudit", bySettingName: PlatformSettings.Allow_WF_SbrBankWithReport  },
    { "id": "6a4f7399-b020-471c-8ebc-27489f5e7bc0", workflowType: WorkFlowTypeEnum.SbrNexus, translationKey: "WorkFlowRequests.RequestTypeNames.NoabBankAudit", bySettingName: PlatformSettings.Allow_WF_NoabBankAudit  }
  ];
  constructor(private httpClient: HttpClient) { }

  async getWorkflowList(): Promise<WorkflowListItem[]> {
    // This will be changed to a backend call, once the workflows are registered in the database.
    return this.possibleWorkFlows;
  }

  async getWorkflowById(workflowId: string): Promise<Workflow> {
    // This will be changed to a backend call, once the workflows are registered in the database.
    let fileName = `assets/workflows/${workflowId.toLowerCase()}.json`
    let workflow = await lastValueFrom(this.httpClient.get(fileName));
    return workflow as Workflow;
  }
}
export enum WorkFlowTypeEnum {
  Generic = 1,
  Tax = 2,
  ChamberOfCommerce = 3,
  SbrNexus = 4,
}
