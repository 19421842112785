import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";

@Directive({
    selector: '[WiggleActiveAction]'
})
export class WiggleDirective implements OnInit {
    constructor(private el: ElementRef) {
    }
    ngOnInit(): void {
        if (this.el.nativeElement.classList.contains('session-started')) {
            this.el.nativeElement.classList.add('wiggle');
        }
    }
    @HostListener('click', ['$event']) onClick($event: any) {
        if (
            this.el.nativeElement.classList.contains('wiggle')) {
            this.el.nativeElement.classList.remove('wiggle');
        }
    }
}