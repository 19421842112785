import { EngineSignatureField } from "./EngineSignatureField";

export class SignatureField extends EngineSignatureField {
    public acronym!: string;
    public fullname!: string;
    public actorId!: string;
    public dragposition!: any;
    public readonly: boolean = true;
    public tempx!: number;
    public tempy!: number;
    public id?: string;
    public hasSigned!: boolean;
    public sigFieldW!: number;
    public sigFieldH!: number;
    // public page!: number;
}
